<template>
  <div class="staticPageManagementBox">
    <ds-header title="静态页面管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="标题">
        <a-input v-model.trim="searchForm.title" placeholder="请输入标题" class="width200" label-width="70px" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="reset">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.staticPageManagement.add)">
      <a-button type="primary" @click="goStaticPageEdit('add')">
        <a-icon type="plus" />
        添加静态页
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false" bordered @change="pageChange">
        <span slot="imgUrl" slot-scope="text, record">
          <img :src="record.imgUrl" alt="" class="imgUrl" @click="handlePreview(record.imgUrl, record.title)">
        </span>
        <span slot="h5Url" slot-scope="text, record" class="h5Url">
          <span>{{ record.h5Url }}</span>
          <a-button type="link" @click="copyH5url(record.h5Url)">复制</a-button>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="goStaticPageEdit('look', record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.staticPageManagement.look)"> 查看
          </a-button>
          <a-button class="btnClass" type="link" @click="goStaticPageEdit('edit', record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.staticPageManagement.edit)"> 编辑
          </a-button>
          <a-button class="btnClass" type="link" @click="remove(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.staticPageManagement.delete)"> 删除
          </a-button>
        </span>
      </a-table>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" width="350px" style="background-color: rgba(0, 0, 0, 0);">
      <div style="margin-top: 20px;">
        <div class="flat-patternmaking-bg">
          <img :src="'https://oss-workplace-prod.innoecos.cn/picture-prod/08a1bf301bc10000_phone-bg.png'" class="bg" alt="">
          <!-- <img :src="'@/assets/phone-bg.png'" class="bg" alt=""> -->
          <div class="container">
            <div class="navigation-view">{{ previewTitle }}</div>
            <div class="content">
              <img alt="example" style="width: 100%; z-index: 10;" :src="previewImage" />
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import help from './help';
import * as api from '@/api/staticPageManagement/index'
import pageRules from "@/utils/pagingConfig/pageUtils.js";
export default {
  data() {
    return {
      table: {
        ...help.table
      },
      searchForm: {
        title: ''
      },
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
    };
  },
  methods: {
    /**网络请求 */
    // 获取静态页列表
    async getList() {
      try {
        const res = await api.getStaticPageList({
          ...this.searchForm,
          pageNo: this.table.pagination.current,
          pageSize: this.table.pagination.pageSize
        })
        if (res.code === this.$SUCCESS_CODE) {
          this.table.dataInfo = res.data.data;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {

      }
    },
    // 删除静态页
    async deleteStaticPage(id) {
      try {
        const res = await api.deleteStaticPage({ id });
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.table.pagination.current = pageRules.pageRules(
            this.table.dataInfo.length,
            1,
            this.table.pagination.current
          );
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      this.table.pagination.current = 1;
      this.getList();
    },
    reset() {
      this.searchForm = {
        title: ''
      }
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    goStaticPageEdit(type, record = {}) {
      this.$router.push({
        path: '/staticPageManagement/add',
        query: {
          type,
          id: record.id ? record.id : null
        }
      })
    },
    remove(record) {
      const self = this;
      this.$confirm({
        title: '确定要删除当前静态页吗？',
        onOk() {
          self.deleteStaticPage(record.id);
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    async copyH5url(h5Url) {
      try {
        navigator.clipboard.writeText(h5Url);
        this.$message.success('复制成功')
      } catch (error) {

      }

    },
    handlePreview(imgUrl, title) {
      this.previewVisible = true;
      this.previewImage = imgUrl;
      this.previewTitle = title;
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    let handleResize = ()=> {
      let width = document.getElementsByClassName('staticPageManagementBox')[0].clientWidth;
      console.log(width,'=====');
      if(width>1690){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>

<style lang="scss" scoped>
.imgUrl {
  width: 37px;
  height: 85px;
  cursor: pointer;
  object-fit: cover;
}

.h5Url {
  width: 650px;
  display: flex;
  justify-content: space-between;
}

.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.flat-patternmaking-bg {
  width: 290px;
  height: 569px;
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .container {
    position: absolute;
    left: 16px;
    top: 40px;
    width: 259px;
    height: calc(100% - 53px);
    z-index: 10;
    .navigation-view {
      height: 31px;
      width: 100%;
      text-align: center;
      line-height: 31px;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
    }
    .content {
      position: absolute;
      left: 0;
      top: 31px;
      height: calc(100% - 33px);
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 26px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: rgb(38, 38, 38);
    }
  }
  .content::-webkit-scrollbar {
    width: 0 !important;
  }
}

::v-deep .ant-modal-content {
  box-shadow: none !important;
  background-color: #ffffff00;
}
</style>